import { useEffect, useState } from "react";
import _camelCase from "lodash/camelCase";
import {
  LocalizationDef,
  UiSettingsDef,
} from "@app/features/localization/localization";
import { getEntry } from "../configs/contentful.client";

const LOCALIZATION_KEY = "6NLfZk3ibt5CIk25c1GOIv"; // - staging
// const LOCALIZATION_KEY = "6NLfZk3ibt5CIk25c1GOIv"; // - - production

function useFetchSettings() {
  const [settings, setSettings] = useState<LocalizationDef | null>(null);
  const [loading, setLoading] = useState(false);

  async function fetchEntry(id: string) {
    const data = await getEntry(id, {
      include: 2,
    });
    return data;
  }

  useEffect(() => {
    setLoading(true);
    fetchEntry(LOCALIZATION_KEY)
      .then((result: any) => {
        if (result) {
          /* this part is tied into setup in contentful (settings models) */
          const localizationTemp: LocalizationDef = {};

          /* Settings - text "translations" for headings, buttons etc */
          result.fields.settingsSet.forEach((settingsSet: any) => {
            settingsSet.fields.settings.forEach((setting: any) => {
              localizationTemp[_camelCase(settingsSet.fields.name)] = {
                ...localizationTemp[_camelCase(settingsSet.fields.name)],
                ...{
                  [_camelCase(setting.fields.key)]: setting.fields.value,
                },
              };
            });
          });

          /* Settings - optional ui settings for visual elements */
          const uiSettingsTemp: UiSettingsDef = {};

          result.fields.uiSettings.forEach((uiSettingItem: any) => {
            const { key, backgroundColor } = uiSettingItem.fields;
            uiSettingsTemp[_camelCase(key)] = {
              backgroundColor,
            };
          });

          localizationTemp.uiSettings = uiSettingsTemp;

          setSettings(localizationTemp);
        }
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { settings, loading };
}
export default useFetchSettings;
